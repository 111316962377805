const localeCurrencyMap = {
  'pt-BR': 'BRL',
  'en-US': 'USD',
}

type FormatterMap = {
  [locale: string]: Intl.NumberFormat
}

const formatters: FormatterMap = {}

export function formatCurrency(value = 0, locale: string = 'pt-BR') {
  if (!formatters[locale]) {
    formatters[locale] = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: localeCurrencyMap[locale] || 'USD',
    })
  }
  return formatters[locale].format(value)
}
