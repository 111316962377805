import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Summary } from '../types'

export const SUMMARY = gql`
  query GetSummary($clientId: Float!) {
    salaryAdvanceSummary(clientId: $clientId) {
      totalAmountAdvanceableInCurrentCycle
      amountAvailableToAdvanceToday
      amountAdvancedInCurrentCycle
      fee
      date
      currentCycleEndDate
    }
  }
`

/**
 * @param clientId <Number> format: 34232
 */
export const useGetSummary = (clientId: number) => {
  return useQuery<Summary>(SUMMARY, {
    fetchPolicy: 'no-cache',
    variables: { clientId },
  })
}
