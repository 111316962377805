export const monthAndYearFormat = (
  date: string | undefined,
  locale: string = 'pt-BR',
): string => {
  if (!date) return '-'
  return new Date(date).toLocaleString(locale, {
    month: 'long',
    year: 'numeric',
  })
}
