import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { ChartStatus } from '../types'

export const CHART_STATUS = gql`
  query GetChartStatus($clientId: Float!) {
    salaryAdvanceChartStatus(clientId: $clientId) {
      totalAmountAdvanceableInCurrentCycle
      amountAvailableToAdvanceToday
      amountAdvancedInCurrentCycle
      maxAmountAdvanceableDaily
      status
    }
  }
`

/**
 * @param clientId <Number> format: 42323
 */
export const useGetChartStatus = (clientId: number) => {
  return useQuery<ChartStatus>(CHART_STATUS, {
    fetchPolicy: 'no-cache',
    variables: { clientId },
  })
}
