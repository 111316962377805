import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider as LegacyThemeProvider } from '@creditas/stylitas'
import { ThemeProvider, standard } from '@creditas-ui/react'
import tokens from '@creditas/tokens'

import { TranslatedErrorBoundary } from '../../components'
import { ApolloClient } from '../../services/bff/apollo-client'
import { OverviewCustomProps } from './index.spa'
import { useGetSummary } from '../../services/bff/queries'
import { Overview } from './Overview'
import { setUserContext } from '../../utils'

class App extends React.Component<OverviewCustomProps> {
  componentDidCatch() {}

  componentDidMount() {
    const { clientId } = this.props
    setUserContext({ id: clientId.toString() })
  }

  render() {
    const { authToken, clientId } = this.props
    const apolloClient = ApolloClient.getAuthorizedClient(authToken)
    return (
      <TranslatedErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <LegacyThemeProvider theme={{ ...tokens }}>
            <ThemeProvider theme={standard}>
              <Overview clientId={clientId} request={useGetSummary} />
            </ThemeProvider>
          </LegacyThemeProvider>
        </ApolloProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
