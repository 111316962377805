import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  OverviewBox,
  OverviewPeriod,
  OverviewDetails,
  OverviewKnowMore,
  OverviewDetailItem,
} from '../../components/Overview'
import { formatCurrency } from '../../helpers'
import { useGetPolicy } from '../../services/bff/queries/getPolicy'

const Overview = ({ clientId, request }) => {
  const { t } = useTranslation()
  const { data } = request(clientId)
  const { data: { policy } = {} } = useGetPolicy(clientId)

  return (
    <OverviewBox>
      <OverviewPeriod
        text={t('title')}
        period={data?.salaryAdvanceSummary.currentCycleEndDate}
      />

      <OverviewDetails>
        <OverviewDetailItem
          label={t('maxWithdrawAmountInMonth')}
          value={formatCurrency(
            data?.salaryAdvanceSummary.totalAmountAdvanceableInCurrentCycle,
          )}
        />

        {/* TODO: Create that new field on BFF and Mcfly */}
        {/* <OverviewDetailItem
          label={t('monthWithdrawLimit')}
          value={formatCurrency(
            data?.salaryAdvanceSummary.totalAmountAdvanceableInCurrentCycle,
          )}
        /> */}

        <OverviewDetailItem
          label={t('diaryWithdrawLimit')}
          value={formatCurrency(
            data?.salaryAdvanceSummary.amountAvailableToAdvanceToday,
          )}
        />

        <OverviewDetailItem
          label={t('alreadyAntecipated')}
          value={formatCurrency(
            data?.salaryAdvanceSummary.amountAdvancedInCurrentCycle,
          )}
        />
        <OverviewDetailItem
          label={t('feeOperation')}
          value={formatCurrency(data?.salaryAdvanceSummary.fee)}
        />
      </OverviewDetails>

      <>
        <OverviewKnowMore
          buttonText={t('actionKnowMore')}
          policyData={policy}
        />
      </>
    </OverviewBox>
  )
}

export { Overview }
