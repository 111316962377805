import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Confirmation } from '../types'

export const CONFIRMATION = gql`
  query GetConfirmation($clientId: Float!) {
    salaryAdvanceConfirmation(clientId: $clientId) {
      amount
      totalAmountAdvanceableInCurrentCycle
      amountAvailableToAdvanceToday
      amountAdvancedInCurrentCycle
      fee
      amountToBeDiscounted
      currentCycleEndDate
      salaryAdvanceId
      maxAmountAdvanceableDaily
      banker
      signatureKey
    }
  }
`

/**
 * @param salaryAdvanceId <String> format: 'jasd-12sad-sjadjas-'
 * @param clientId <Number> format: 42323
 */
export const useGetConfirmation = (clientId: number) => {
  return useQuery<Confirmation>(CONFIRMATION, {
    fetchPolicy: 'no-cache',
    variables: { clientId },
    pollInterval: 3000,
  })
}
