import React from 'react'
import { Typography } from '@creditas-ui/typography'
import { styled, css } from '@creditas-ui/react'
import { monthAndYearFormat } from '../../helpers'

interface Props {
  text: string
  period?: string
  fontSize?: string
}

const Strong = styled(Typography)`
  ${(props: any) =>
      props.fontSize === 'small' &&
      css`
        font-size: 16px;
        line-height: 20px;
      `}
    ::first-letter {
    text-transform: capitalize;
  }
`

const OverviewPeriod = ({
  text,
  period,
  fontSize = 'headingLgMedium',
}: Props) => {
  const monthAndYear = monthAndYearFormat(period)
  return (
    <>
      <Typography variant="bodySmLight">{text}</Typography>
      <Strong variant={fontSize} component="h4" data-testid="overview-period">
        {monthAndYear}
      </Strong>
    </>
  )
}

export { OverviewPeriod }
