import React from 'react'
import { Typography } from '@creditas-ui/typography'
import { styled } from '@creditas-ui/system'

interface StyleProps {
  noMargin?: boolean
  color?: string
}
interface Props extends StyleProps {
  total?: string
  title: string
}

const Div = styled.div`
  text-align: center;
  width: 100%;
  ${({ noMargin }: StyleProps) => (!noMargin ? `margin-bottom: 38px` : '')};
`

const TotalAmount = styled(Typography)`
  display: block;
  ${({ noMargin }: StyleProps) => (!noMargin ? `margin-top: 17px;` : '')};
`

const OverviewAmountTotal = ({
  title,
  total,
  noMargin = false,
  color = 'primary.60',
}: Props) => {
  return (
    <Div noMargin={noMargin}>
      <Typography variant="bodyMdLight">{title}</Typography>
      <TotalAmount
        data-testid="overview-amount-total"
        noMargin={noMargin}
        color={color}
        variant="headingMdMedium"
      >
        {total}
      </TotalAmount>
    </Div>
  )
}

export { OverviewAmountTotal }
