import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { ApolloClient as ApolloClient_ } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'

import { envVars } from '../../envVars'

export class ApolloClient {
  private static client: ApolloClient_<NormalizedCacheObject>

  static getAuthorizedClient(authorization: string) {
    if (!this.client) {
      this.client = this.createClient(authorization)
    }
    return this.client
  }

  private static createClient(authorization: string) {
    const httpLink = createHttpLink({ uri: envVars.BFF_URI })
    const authLink = setContext((_, { headers }) => ({
      headers: { ...headers, authorization },
    }))
    return new ApolloClient_({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    })
  }
}
