import { styled, selectTheme } from '@creditas-ui/react'
import { ButtonSecondary } from '@creditas-ui/react'

const Box = styled.div`
  margin-top: 20px;
  width: 100%;
`

const CustomActionLink = styled(ButtonSecondary)`
  user-select: none;
  padding: 0;

  > i > svg > path:first-child {
    stroke: ${selectTheme('colors.primary.60')};
  }
`

const Description = styled.div`
  margin-top: 14px;
  width: 100%;
  p {
    margin: 0 0 25px 0;

    &:last-child {
      margin: 0;
    }
  }
`

export { Box, CustomActionLink, Description }
