import React, { useState } from 'react'
import { Box, CustomActionLink, Description } from './Details.style'
import { GlobalIcons } from '@creditas-ui/global-icons'
import { IconArrow1, IconArrow2 } from '@creditas-ui/icons'

interface Props {
  buttonText: string
}

const Details: React.FC<Props> = ({ buttonText, children, ...props }) => {
  const [opened, setOpened] = useState(false)
  const handleOpened = () => setOpened(!opened)

  const iconActionLink = opened ? IconArrow1 : IconArrow2

  return (
    <Box {...props}>
      <CustomActionLink onClick={handleOpened} data-testid="details-button">
        {buttonText}
        <GlobalIcons Icon={iconActionLink} size="medium" />
      </CustomActionLink>

      {opened && (
        <Description data-testid="details-description">{children}</Description>
      )}
    </Box>
  )
}

export { Details }
