import React from 'react'
import { Typography } from '@creditas-ui/typography'
import { styled } from '@creditas-ui/system'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;

  @media (max-width: 450px) {
    margin-bottom: 19px;
  }

  p {
    max-width: 210px;
    width: 100%;
  }

  > p {
    @media (max-width: 420px) {
      max-width: 127px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const TextAlign: any = styled.div`
  width: 100%;
  text-align: right;
`

const OverviewDetails = styled.div`
  margin-top: 24px;
  margin-bottom: 18px;
`

export interface OverviewDetailsProps {
  label: string
  value?: number | string
}

const OverviewDetailItem: React.FC<OverviewDetailsProps> = props => {
  const { label, value } = props
  return (
    <Container data-testid="overview-detail-item">
      <Typography variant="bodySmLight">{label}</Typography>
      <TextAlign>
        <Typography variant="bodyMdBold">{value}</Typography>
      </TextAlign>
    </Container>
  )
}

export { OverviewDetails, OverviewDetailItem }
