import { selectTheme } from '@creditas-ui/react'
import { styled } from '@creditas-ui/system'

const OverviewBox = styled.div`
  padding: 35px;
  background-color: ${selectTheme('colors.neutral.0')};

  @media (max-width: 450px) {
    padding: 23px;
  }
`

export { OverviewBox }
