import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Typography } from '@creditas/typography'

class ErrorBoundary extends React.Component<WithTranslation> {
  readonly state = { hasError: false }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
    console.log(error)
  }

  render({ t } = this.props) {
    const { hasError } = this.state

    return hasError ? (
      <Typography variant="h1">{t('shared:phrase.error')}</Typography>
    ) : (
      this.props.children
    )
  }
}

const TranslatedErrorBoundary = withTranslation()(ErrorBoundary)

export { TranslatedErrorBoundary }
